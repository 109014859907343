
// ===== SCREEN READER HELPERS
// ================================================================================

// Visually hidden content that only "appears" to screen readers
// See: https://a11yproject.com/posts/how-to-hide-content/
// See: https://hugogiraudel.com/2016/10/13/css-hide-and-seek/
@mixin sr-only() {
	position: absolute;
	height: 1px;
	width: 1px;
	padding: 0;
	border: 0;
	clip: rect(0, 0, 0, 0);
	overflow: hidden;
	white-space: nowrap;
}

// Similar to .sr-only, but will display content when it's focused
// Useful for "Skip to main content" links
// See: https://www.w3.org/TR/2013/NOTE-WCAG20-TECHS-20130905/G1
@mixin sr-only-focusable() {
	@include sr-only;
	&:active,
	&:focus {
		position: static;
		height: auto;
		width: auto;
		clip: auto;
		overflow: visible;
		white-space: normal;
	}
}