// ===== STICKY FOOTER BAR
// ====================================================================

.sticky-footer-bar {
    position: fixed;
    bottom: 0;
    left: 0;
    transform: translateY(100%);
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    width: 100%;
    z-index: 1000;
    transition: 0.2s;
    
    @include bp($bp-col8) {
        justify-content: center;
    }
    
    &.active {
        transform: translateY(0);
    }

    // Open State
    &.open {
        .offer-button {
            .icon {
                transform: scaleY(-1);
            }

            @include bp($bp-col12) {
                transform: translateY(100%);
            }
        }

        .sticky-footer-form {
            pointer-events: all;
            transform: translateY(-100%);
            max-height: 100vh;

            @include bp($bp-col8) {
                transform: translateY(calc(-100% + 55px));
            }
            
            @include bp($bp-col12) {
                transform: translateY(0);
                opacity: 1;
                transition: transform 0s, opacity 0.2s;
            }

            &:before {
                transform: translateY(-100%);
                opacity: 1;
                transition: opacity 0.2s 0.1s;
            }
        }
    }

    .offer-button {
        cursor: pointer;
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        background: $yellow;
        padding: 15px;
        z-index: 1;

        @include bp($bp-col8) {
            transform: translateY(10px);
            flex-direction: row-reverse;
            width: auto;
            border-top-left-radius: 5px;
            border-top-right-radius: 5px;
            box-shadow: 0px 2px 7px rgba(black, 0.2);
            padding: 13px 30px 23px 30px;
            transition: transform 0.15s;

            &:hover {
                transform: translateY(0);
            }
        }

        .icon {
            display: flex;
            margin-right: 20px;
            transition: 0.2s;
            animation-duration: 2s;
            animation-iteration-count: infinite;
            animation-name: icon-bounce;
            animation-timing-function: ease;

            @include bp($bp-col8) {
                margin: 0 0 0 10px;
            }

            svg {
                width: 24px;
                height: 7px;
                fill: $blue;

                @include bp($bp-col8) {
                    width: 14px;
                    stroke: $blue;
                    stroke-width: 1px;
                }
            }
        }

        @keyframes icon-bounce {
            0%   { transform: translateY(0); }
            30%  { transform: translateY(-5px); }
            50%  { transform: translateY(0); }
            100% { transform: translateY(0); }
        }

        .text {
            color: $blue;
            font: $regular 14px/1.2 $font;
            text-transform: uppercase;
            text-align: center;

            @include bp($bp-col8) {
                display: flex;
                font-size: 16px;
                
                .line {
                    margin-right: 5px;
                }
            }

            .bold {
                font-weight: $bold;
            }
        }

        &:hover {
            .icon {
                animation-play-state: paused;
            }
        }
    }

    .phone {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        background: $blue;
        padding: 0 22px;
        z-index: 1;

        @include bp($bp-col8) {
            display: none;
        }

        svg {
            transform: scaleX(-1);
            width: 22px;
            height: 22px;
            fill: white;
        }
    }
}


// ===== STICKY FOOTER FORM
// ====================================================================

.sticky-footer-form {
    pointer-events: none;
    position: absolute;
    top: 0;
    left: 0;
    transform: translateY(100%);
    width: 100%;
    max-height: 0;
    background: white;
    padding: 40px 0 30px 0;
    transition: 0.2s;
    will-change: transform;

    @include bp($bp-col8) {
        padding-bottom: 100px;
    }

    @include bp($bp-col12) {
        top: auto;
        bottom: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100vh;
        background: rgba($blue, 0.95);
        opacity: 0;
        padding-bottom: 30px;
        transition: transform 0s 0.2s, opacity 0.2s;
    }

    // Dark Overlay
    &:before {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        transform: translateY(0%);
        height: 100vh;
        background: rgba(#555, 0.65);
        opacity: 0;
        transition: opacity 0.2s, transform 0s 0.2s;

        @include bp($bp-col12) {
            content: none;
        }
    }

    .wrapper {
        position: relative;
        width: 100%;
        max-width: 580px;
        padding: 0 20px;
        margin: 0 auto;

        @include bp($bp-col12) {
            max-width: 850px;
        }
    }

    .close-button {
        cursor: pointer;
        position: absolute;
        top: -22px;
        right: 20px;
        display: flex;
        
        &:hover svg {
            opacity: 0.75;
        }
        
        svg {
            width: 22px;
            height: 22px;
            fill: $blue;
        }
        
        @include bp($bp-col12) {
            top: -30px;
            right: 0px;

            svg {
                width: 30px;
                height: 30px;
                fill: white;
            }
        }

        @include bp($bp-col16) {
            top: -50px;
            right: -100px;

        }
    }

    .form-title {
        position: relative;
        max-width: 200px;
        color: $blue;
        font: $bold 22px/1.2 $font;
        text-align: center;
        padding-bottom: 10px;
        margin: 0 auto 30px auto;

        @include bp($bp-col12) {
            color: white;
            font-size: 34px;
            max-width: none;
            padding-bottom: 25px;
        }

        &:after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 50%;
            transform: translateX(-50%);
            width: 150px;
            height: 2px;
            background: $orange;

            @include bp($bp-col12) {
                width: 300px;
            }
        }
    }

    .content {
        display: none;
        width: 100%;
        color: white;
        font: $regular 20px/1 $font;
        text-align: center;
        margin: 0 0 45px 0;

        @include bp($bp-col12) {
            display: block;
        }
    }

    form {
        max-width: 580px;
        margin: 0 auto;
    }

    .form-group {

        @include bp($bp-col12) {
            margin-bottom: 12px;

        }

        &.half {
            width: calc(50% - 4px);
            
            @include bp($bp-col12) {
                width: calc(50% - 6px);
            }
        }
        
        label {
            color: $black;
            font-size: 14px;
        }

        &.email-sticky {
            @include bp(max-width $bp-col8-max) {
                width: 100%;
            }
        }

        &.comments-sticky {
            display: none;

            @include bp($bp-col12) {
                display: block;
            }
        }

        &.form-group-btn {
            margin: 0 auto 0 auto;
        }
    }

    .form-disclaimer {
        width: auto !important;
        max-width: none !important;
        color: $black;
        font: $regular 9px/1.35 $font !important;
        margin: 20px 0;

        @include bp($bp-col12) {
            color: white;
            font-size: 10px !important;
            margin-bottom: 30px;
        }

        a {
            color: inherit;
            font-weight: $bold;

            @include bp($bp-col12) {
                display: table;
            }
        }
    }
}

// Hide Lastpass icons
div[id^=__lpform_] {
    display: none;
}
