
// ===== PRIMARY CONTENT PERIMETER
// ================================================================================

.perimeter {
	max-width: 1280px;
	margin: 0 auto;
	padding: 0 20px;
	&.small {
		max-width: 800px;
	}
	&.medium {
		max-width: 960px;
	}
	&.large {
		max-width: 1320px;
	}
}



// ===== CLEARFIX
// ================================================================================

.clearfix {
	&:after {
		display: table;
		clear: both;
		content: '';
	}
}



// ===== HELPER CLASSES - FROM WORDPRESS EDITOR
// ================================================================================

.alignleft {
	float: left;
	margin: 0 30px 20px 0;
	@include bp(max-width $bp-col4-max) {
		float: none;
		display: block;
		margin: 0 auto 20px auto;
	}
}
.alignright {
	float: right;
	margin: 0 0 20px 30px;
	@include bp(max-width $bp-col4-max) {
		float: none;
		display: block;
		margin: 0 auto 20px auto;
	}
}
.aligncenter {
	display: block;
	margin: 0 auto 20px auto;
}
.wp-caption {
	margin-bottom: 20px;
	img {
		display: block;
	}
}
.wp-caption-text {
	max-width: calc(100% - 20px);
	margin: 10px auto 0 auto;
	font-size: 11px;
	font-weight: 1.2;
	text-align: center;
}



// ===== HELPER CLASSES - DISPLAY / APPEARANCE
// ================================================================================

.no-margin {
	margin: 0 !important;
}
.no-padding {
	padding: 0 !important;
}
.no-display {
	display: none !important;
}
.no-wrap {
	white-space: nowrap !important;
}



// ===== HELPER CLASSES - ACCESSIBILITY / SCREEN READERS
// ================================================================================

.hide-on-mobile {
	@include bp(max-width $bp-col4-max) {
		display: none;
	}
}

// Useful for text that will be read by screen readers, but hidden to sighted users
.sr-only {
	@include sr-only();
}
// Similar to .sr-only, but will display content when it's focused (useful for the "go to main content" skip link)
.sr-only-focusable {
	@include sr-only-focusable();
}



// ===== HELPER CLASSES - DEBUGGING
// ================================================================================

.debug {
	display: block;
	max-height: 500px;
	padding: 15px;
	background: #37474F;
	border-radius: 5px;
	color: #FFFFFF;
	font: normal 12px/1.25 monospace;
	overflow: auto;
	&:before {
		display: block;
		margin-bottom: 10px;
		content: 'Debugging Info:';
		font-weight: bold;
		text-decoration: underline;
	}
	&.loud {
		background: #FF4569;
	}
}