
// ===== BODY
// ================================================================================

.body-main {
	position: relative;
	z-index: 1;
	.form-signup .btn {
		position: relative;
		svg {
			@include center-y;
			left: 10px;
			display: none;
			height: 20px;
			width: auto;
		}
		&[disabled] svg {
			display: block;
		}
	}
}
.section {
	.ul li:before {
		font-weight: bold;
	}
}



// ===== SECTION - HERO
// ================================================================================

.section-hero {
	position: relative;
	padding: 0;

	.hero {
		position: relative;
		background: $grey;

		@include bp($bp-col8) {
			background: white;
		}

		@include bp($bp-col12) {
			display: flex;
			justify-content: center;
			align-items: center;
			padding: 50px;
		}

		.image {
			height: 240px;
			background: url('../images/hero.jpg') 50% 100%/cover no-repeat;

			@include bp($bp-col8) {
				height: 400px;
			}

			@include bp($bp-col12) {
				position: absolute;
				top: 0;
				right: 0;
				bottom: 0;
				left: 0;
				height: 100%;
				background-position: 50% 80%;
			}

			@include bp(1600px) {
				background-position: 50% 60%;
			}
		}
	}

	.form-perimeter {
		max-width: 560px;
		padding: 0 10px;
		margin: -70px auto 0 auto;

		@include bp($bp-col8) {
			margin-top: -250px;
		}

		@include bp($bp-col12) {
			margin: 0 auto;
		}
	}

	.form-title {

		@include bp($bp-col16) {
			max-width: 400px;
		}

		text-align: center;
		.primary {
			font: 700 20px/1.2 $font;
			margin-bottom: 10px;

			@include bp($bp-col8) {
				font-size: 25px;
			}

			@include bp($bp-col16) {
				font-size: 33px;
			}
		}
		.secondary {
			font: $medium 18px/1 $font;
			padding: 0;
			margin-bottom: 10px;
		}
	}

	.form-body {
		.btn {
			color: $primary;
			@extend .btn-teal;
		}
		.form-group-btn {
			text-align: center;
			margin: 15px auto 0;
		}
	}
	.steps-slider {
		width: 100%;
	}
}


// ===== SECTION - HERO - EXTERNAL FORM
// ================================================================================

.hero .quiz-container {

	h1.ng-scope {
		font-size: 20px !important;
	}

	h2.node-title {
		font-size: 20px !important;
	}

}


// ===== SECTION - HERO PROMO
// ================================================================================

.hero-promo {
	color: white;
	background: $blue;
	padding: 17px 12px;

	@include bp($bp-col16) {
		padding: 25px;
	}

	.perimeter {
		font: $regular 21px/1.2 $font;
		text-align: center;
		border: 1px solid white;
		padding: 15px 10px;

		@include bp($bp-col12) {
			display: table;
			padding: 15px 50px;
		}

		.promo-title {
			color: $yellow;
			font-size: 28px;
			margin-bottom: 12px;
		}

		.promo-body {
			font-size: 16px;
			letter-spacing: 1px;
			line-height: 28px;
			margin-bottom: 12px;

			@include bp($bp-col16) {
				font-size: 18px;
				line-height: 1.15;
			}

			img {
				height: 26px;
				margin-bottom: -13px;

				@include bp($bp-col16) {
					height: 28px;
					margin-bottom: -14px;
				}
			}
		}

		.link {
			cursor: pointer;
			display: table;
			color: $yellow;
			font: $bold 16px/1.5 $font;
			text-transform: none;
			border-bottom: 2px solid $yellow;
			margin: 0 auto;

			@include bp($bp-col12) {
				font-size: 20px;
			}

			.arrow {
				font-size: 12px;
				margin-left: 5px;
			}
		}
	}
}


// ===== SECTION - ABOUT
// ================================================================================

.section-about {
	text-align: center;
	color: #fff;
	background-color: $primary;
	.perimeter {
		max-width: 1020px;
	}
	.section-title {
		color: #fff;
	}
	p {
		font-family: $font-secondary;
	}
	.col2 {
		flex: 1 0 auto;
		font-size: 0;
	}
	.col2 img {
		width: 300px;
		@include bp($bp-col16) {
			width: 425px;
		}
	}
	.col1 > *:last-child {
		margin-bottom: 0;
	}
	@include bp(max-width 959px) {
		.col2 {
			display: none;
		}
	}
	@include bp($bp-col12) {
		padding: 75px 0 0 0;
		font-size: 24px;

		.perimeter {
			display: flex;
			align-items: center;
		}
		.col1 {
			padding-bottom: 75px;
		}
		.col2 {
			margin: auto 45px 0 0;
		}
	}
}


// ===== SECTION - MORE INFO
// ================================================================================

.more-info {
	display: flex;
	justify-content: center;
	width: 100%;
	background: white;

	.button {
		cursor: pointer;
		display: flex;
		justify-content: center;
		align-items: center;
		width: calc(100% - 40px);
		max-width: 395px;
		color: #46697d;
		font: 400 22px/1.55 $font-main;
		text-transform: uppercase;
		background: $grey;
		border-bottom-left-radius: 7px;
		border-bottom-right-radius: 7px;
		padding: 10px;
		margin-bottom: 35px;
		transition: background 0.2s;

		&:hover {
			background: darken($grey, 3%);
		}

		@include bp($bp-col8) {
			font-size: 26px;
			padding: 15px 10px 20px 10px;
			margin-bottom: 50px;
		}

		&.open {
			svg {
				transform: rotate(-90deg);
			}
		}

		svg {
			display: block;
			transform: rotate(90deg);
			width: 8px;
			fill: #46697d;
			margin-left: 20px;
			transition: transform 0.2s;
		}
	}
}

.expandable-content {
	max-height: 0;
	overflow: hidden;
	transition: max-height 1s;

	&.open {
		max-height: 10000px;
		transition: max-height 3s;
	}
}


// ===== SECTION - HOW IT WORKS
// ================================================================================

.section-how-works {
	.section-title {
		text-align: left;
	}
	p {
		font-family: $font-secondary;
	}
	.col2 {
		flex: 1 0 auto;
		font-size: 0;
	}
	.col2 img {
		width: 100%;
	}
	.col1 > *:last-child {
		margin-bottom: 0;
	}
	@include bp(max-width 639px) {
		.perimeter {
			display: flex;
			flex-direction: column-reverse;
		}
		.col1 {
			.section-title {
				text-align: center;
			}
			p {
				display: none;
			}
		}
		.col2 {
			text-align: center;
		}
	}
	@include bp($bp-col8) {
		padding: 30px 0;
		.perimeter {
			display: flex;
			align-items: center;
		}

		.perimeter {
			display: flex;
			align-items: center;
		}
		.col1 {
			.section-title:after {
				left: 0;
				transform: none;
			}
		}
		.col2 {
			margin: 0 45px 0 0;
			width: 40%;
		}
	}
}



// ===== SECTION - FIND
// ================================================================================
.section-find {
	background-color: white;
	padding: 50px 0;

	@include bp($bp-col12) {
		padding: 85px 0;
	}

	.section-subtitle {
		text-align: center;
		max-width: 250px;

		@include bp($bp-col12) {
			max-width: none;
		}
	}

	.grid {
		display: flex;
		flex-direction: column;
		align-items: center;
		margin-bottom: 25px;

		@include bp($bp-col12) {
			flex-direction: row;
			justify-content: center;
			align-items: flex-start;
		}

		.card-container	{
			width: 100%;
			max-width: 290px;

			@for $i from 1 through 6 {
				&:nth-of-type(6n + #{$i}) .image {
					animation-delay: $i * 0.125s;
				}
			}

			@include bp($bp-col12) {
				max-width: none;
			}

			.card {
				display: flex;
				align-items: center;
				margin-bottom: 25px;

				@include bp($bp-col12) {
					flex-direction: column;
					padding: 0 30px;
				}

				.image {
					display: flex;
					margin-right: 15px;

					@include bp($bp-col12) {
						margin: 0 0 45px 0;
					}

					svg {
						width: 70px;
						height: 70px;
						fill: $blue;

						@include bp($bp-col12) {
							width: 160px;
							height: 160px;
						}
					}
				}

				.content {
					display: flex;
					flex-direction: column;
					color: $black;
					font: $regular 14px/1.2 $font;

					@include bp($bp-col12) {
						font-size: 20px;
						text-align: center;
					}

					.title {
						color: $orange;
						font-weight: $bold;
						text-transform: uppercase;
						margin-bottom: 5px;

						@include bp($bp-col12) {
							max-width: 240px;
							font-size: 23px;
							margin: 0 auto 10px auto;
						}
					}
				}
			}
		}
	}

	.js-form-modal {
		display: block;
		max-width: 225px;
		margin: 0 auto;

		@include bp($bp-col8) {
			display: table;
			max-width: none;
		}
	}
}


// ===== SECTION - TESTIMONIALS
// ================================================================================

.section-testimonials {
	color: $black;
	font: $regular 14px/1.5 $font;
	text-align: center;
	background-position: center;
	background-size: cover;
	background-repeat: no-repeat;

	@include bp($bp-col12) {
		font-size: 18px;
	}

	@include bp($bp-col16) {
		font-size: 20px;
	}

	> .perimeter {
		display: flex;
		flex-direction: column;
		align-items: center;
		width: 100%;
		max-width: 800px;

		@include bp($bp-col12) {
			flex-direction: row;
			justify-content: space-between;
			align-items: flex-start;
			max-width: 1320px;
		}
	}

	.content-container {
		margin-bottom: 40px;

		@include bp($bp-col12) {
			width: 50%;
			padding: 0 50px;

			.section-title {
				max-width: 400px;
				margin: 0 auto 40px auto;
			}
		}

		:last-child {
			margin-bottom: 0;
		}
	}

	.reviews-container {
		width: 100%;
		@include bp($bp-col12) {
			width: 50%;
			padding: 0 60px;
		}
	}

	.google {
		.logo {
			display: flex;
			justify-content: center;
			margin-bottom: 20px;

			svg {
				width: 95px;
				height: auto;

				@include bp($bp-col12) {
					width: 125px;
				}
			}
		}
		.stars {
			display: none;
			width: 160px;
			margin: 0 auto 35px auto;

			@include bp($bp-col12) {
				display: block;
			}
		}
	}

	.carousel {
		width: 100%;
		max-width: 500px;
		color: $black;
		font: italic $regular 14px/1.5 $font;
		margin: 0 auto;

		@include bp($bp-col12) {
			max-width: none;
			font-size: 18px;
		}

		@include bp($bp-col16) {
			font-size: 20px;
		}

		.slide {
			padding: 0 19px;

			.byline {
				font-style: normal;
				font-weight: $bold;
				margin-top: 10px;

				@include bp($bp-col12) {
					margin: 30px auto 40px auto;
				}
			}
		}

		.stars {
			width: 120px;
			margin: 20px auto 35px auto;

			@include bp($bp-col12) {
				display: none;
			}
		}
	}

	.ctrls {
		display: flex;
		align-items: center;
		justify-content: center;
		margin-top: 0px;
	}

	.ctrl {
		cursor: pointer;
		padding: 5px;
		svg {
			display: block;
			width: 12px;
			height: 22px;
			fill: $blue;
		}
	}

	.pager {
		ul {
			display: flex !important;

			li {
				padding: 0 4px;
			}
		}
		button {
			cursor: pointer;
			position: relative;
			display: block;
			height: 24px;
			width: 24px;
			font-size: 0;
			background: none;
			border: 0;
			outline: none;
			&:before,
			&:after {
				@include center-xy;
				border-radius: 50%;
				content: '';
			}
			&:before {
				height: 14px;
				width: 14px;
				background: $blue;
			}
			&:after {
				display: none;
				height: 24px;
				width: 24px;
				border: 2px solid $blue;
			}
		}

		.slick-active button:after {
			display: block;
		}
	}
}



// ===== SECTION - GALLERY
// ================================================================================

.section-gallery {
	position: relative;
	background: #f5f7fb;
    padding: 45px 0;

    @include bp($bp-col8) {
        padding: 65px 0;
	}

	.slider-wrapper {
		position: relative;
		width: 100%;
		max-width: 1070px;
		margin: 0 auto;
	}

    .slider {
        width: 100%;

        @include bp($bp-col8) {
            display: flex;
            justify-content: stretch;
            padding: 0 10px;
        }

        .slick-list,
        .slick-track {
            display: flex;
            height: inherit;
            padding: 30px 0;

            .slick-slide,
            .slick-slide > div {
                display: flex;
                width: 100%;
                height: inherit;
            }
        }

        .slick-slide {
			opacity: 0.25;
            transition: 0.2s;
            transition-delay: 0.5s;
        }

        .slick-current {
			transform: translateY(-20px);
			opacity: 1;

            .slide {
                box-shadow: 8px 8px 13px rgba(black, 0.22);
            }
        }

        .slide-container {
            display: flex;
            width: 100%;
            height: inherit;
            padding: 0 5px;

            @include bp($bp-col6) {
                padding: 0 12px;
            }

            @include bp($bp-col8) {
                padding: 0 5px;
            }

            @include bp($bp-col12) {
                padding: 0 12px;
            }
        }

        .slide {
            display: flex;
            flex-direction: column;
            width: 100%;
			height: 190px;
            text-decoration: none;
			background-position: center;
			background-size: cover;
			background-repeat: no-repeat;
            box-shadow: 0 10px 10px rgba(black, 0);
            transition: box-shadow 0.2s 0.5s;

            @include bp($bp-col8) {
				height: 250px;
                transition: 0.2s;
			}

            @include bp($bp-col12) {
				height: 400px;
            }
        }
	}

	.ctrls {
		display: flex;
		align-items: center;
		justify-content: center;
		margin-top: 0px;
	}

	.ctrl {
		cursor: pointer;
		padding: 5px;

		svg {
			display: block;
			width: 12px;
			height: 22px;
			fill: $blue;

			@include bp($bp-col16) {
				width: 25px;
				height: 40px;
				stroke: $blue;
				stroke-width: 3px;
				overflow: visible;
				transition: opacity 0.2s;

				&:hover {
					opacity: 0.85;
				}
			}
		}

		&.ctrl-prev {
			@include bp($bp-col16) {
				position: absolute;
				top: 200px;
				left: -85px;
			}
		}
		&.ctrl-next {
			@include bp($bp-col16) {
				position: absolute;
				top: 200px;
				right: -85px;
			}
		}
	}

	.pager {
		ul {
			display: flex !important;

			li {
				padding: 0 4px;
			}
		}
		button {
			cursor: pointer;
			position: relative;
			display: block;
			height: 24px;
			width: 24px;
			font-size: 0;
			background: none;
			border: 0;
			outline: none;
			&:before,
			&:after {
				@include center-xy;
				border-radius: 50%;
				content: '';
			}
			&:before {
				height: 14px;
				width: 14px;
				background: $blue;
			}
			&:after {
				display: none;
				height: 24px;
				width: 24px;
				border: 2px solid $blue;
			}
		}

		.slick-active button:after {
			display: block;
		}
	}

	.js-form-modal {
		display: table;
		margin: 35px auto 0 auto;

		@include bp($bp-col12) {
			margin-top: 45px;
		}
	}
}


// ===== SECTION - FINAL CTA
// ================================================================================

.section.section-associations {
	background: transparent;
	.logo-container {
		display: flex;
		justify-content: center;
		align-items: center;
		flex-wrap: wrap;
		.item-container {
			width: 33%;
			text-align: center;
			padding: 10px 5px;

			img {
				display: block;
				width: 100%;
				max-width: 90px;
				margin: 0 auto;
			}

			@include bp($bp-col8) {
				width: calc(100 * 1/6) + '%';
				padding: 22px;

				img {
					max-width: none;
				}
			}
		}
	}
}


// ===== SECTION - FINAL CTA
// ================================================================================

.section-final-cta {
	background: $primary;
	padding: 50px 0 45px;

	.perimeter {
		max-width: 1090px;
	}

	.thank-you-text {
		max-width: 600px;
		color: white;
		text-align: center;
		margin: 0 auto;
	}

	.section-title {
		color: white;
		max-width: 220px;
		margin: 0 auto 30px auto;

		@include bp($bp-col8) {
			max-width: none;
			margin-bottom: 45px;
		}

		&:after {
			background: $secondary;
		}
	}

	.row {
		display: flex;
		flex-direction: column;

		@include bp($bp-col12) {
			flex-direction: row;
		}

		.content {
			width: 100%;
			max-width: 400px;
			color: white;
			font: $regular 18px/1.4 $font-secondary;
			text-align: center;
			padding: 0;
			margin: 0 auto 35px auto;

			@include bp($bp-col12) {
				max-width: 460px;
				font-size: 20px;
				text-align: left;
				padding: 5px 65px 0 0;
				margin: 0;
			}

			.features {
				width: 100%;
				list-style-type: none;
				padding: 0;
				margin: 25px 0 0 0;

				@include bp($bp-col8) {
					margin-top: 30px;
				}

				.feature {
					font: 400 14px/1.25 $font-secondary;
					text-align: center;
					margin-bottom: 2px;

					@include bp($bp-col12) {
						font-size: 16px;
						text-align: left;
					}

					@include bp($bp-col16) {
						font-size: 18px;
					}

					svg {
						width: 16px;
						height: 16px;
						margin-right: 10px;
						margin-bottom: -2px;

						@include bp($bp-col12) {
							width: 25px;
							height: 25px;
							margin-bottom: -4px;
						}
					}

					strong {
						font-weight: $bold;
					}
				}
			}
		}
		.form {
			width: 100%;
			max-width: 375px;
			margin: 0 auto;

			@include bp($bp-col12) {
				flex: 1 0 auto;
				width: 55%;
				max-width: none;
			}

			.form-signup {
				display: flex;
				flex-direction: row;

				@include bp($bp-col12) {
					flex-direction: row;
					flex-wrap: wrap;
				}

				label {
					color: $black;
					font-size: 14px;
				}

				.form-group {
					margin-bottom: 12px;
					&.comments2 {
						padding-right: 0px;
					}

					@include bp($bp-col12) {
						width: 50%;

						&:nth-of-type(odd) {
							padding-right: 12px;
							&.comments2 {
								padding-right: 0px;
							}
						}
					}
				}

				.form-group-btn {
					width: 100%;
					text-align: center;
					margin: 15px auto 30px auto;

					.btn {
						background: $secondary;

						&:hover {
							background: lighten($secondary, 15%);
							cursor: pointer;
						}
					}
				}

				.form-disclaimer {
					label {
						color: white;
						font-size: 9px !important;
						line-height: 1.35;
					}
				}
			}
		}
	}

	.disclaimer-row {
        padding: 40px 20px 0 20px;

        @include bp($bp-col8) {
            padding-top: 40px 0 0 0;
        }

        p {
			color: #fff;
            font-size: 12px;
			margin-bottom: 12px;
			margin-top: 0;
        }
    }
}


// ===== SECTION - RETURN HOME
// ================================================================================

.section-return-home {
	padding: 70px 0;
	text-align: center;
	.pg-gallery & {
		padding-top: 0;
	}
}



// ===== MODAL - PRIVACY POLICY
// ================================================================================

.modal-privacy-policy {
	.modal-body-wrapper {
		max-width: 750px;
	}
	.modal-copy {
		font-size: 16px;
	}
	.title {
		color: darken($grey, 15%);
		font: $bold 24px/1 $font-main;
	}
	.subtitle {
		color: darken($grey, 15%);
		font-size: 18px;
		font-weight: 700;
	}
}



// ===== MODAL - FORM RESPONSE
// ================================================================================

.modal-form-status {
	.modal-body-wrapper {
		max-width: 680px;
	}
	.modal-copy {
		text-align: center;
	}
	.title {
		margin-bottom: 15px;
		color: darken($grey, 15%);
		font: $bold 24px/1 $font-main;
	}
}
