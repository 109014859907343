// ===== FONTS
// ================================================================================

/* open-sans-regular - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: local('Open Sans Regular'), local('OpenSans-Regular'),
       url('../fonts/open-sans-v17-latin-regular.woff2') format('woff2'),
       url('../fonts/open-sans-v17-latin-regular.woff') format('woff');
}
/* open-sans-700 - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  src: local('Open Sans Bold'), local('OpenSans-Bold'),
       url('../fonts/open-sans-v17-latin-700.woff2') format('woff2'),
       url('../fonts/open-sans-v17-latin-700.woff') format('woff');
}

/* source-sans-pro-regular - latin */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 400;
  src: local('Source Sans Pro Regular'), local('SourceSansPro-Regular'),
       url('../fonts/source-sans-pro-v13-latin-regular.woff2') format('woff2'),
       url('../fonts/source-sans-pro-v13-latin-regular.woff') format('woff');
}
/* source-sans-pro-700 - latin */
@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 700;
  src: local('Source Sans Pro Bold'), local('SourceSansPro-Bold'),
       url('../fonts/source-sans-pro-v13-latin-700.woff2') format('woff2'),
       url('../fonts/source-sans-pro-v13-latin-700.woff') format('woff');
}

$font-main: 'Montserrat', Helvetica, Arial, sans-serif;
$font-secondary: 'Montserrat', Helvetica, Arial, sans-serif;

$font: 'Montserrat', Helvetica, Arial, sans-serif;
$font-open: 'Open Sans', Helvetica, Arial, sans-serif;

// Font Weights for Montserrat
$regular: 400;
$medium: 500;
$bold: 700;


// ===== COLORS
// ================================================================================

$primary: #0177BE;
$secondary: #EE8B20;
$tertiary: #44687d;

$grey: #f5f7fb;
$grey-dark: #bababa;
$black: #141414;

$blue: #365fa7;
$blue-light: #1290cd;
$orange: #EE8B20;
$yellow: #ffc950;

// ===== BREAKPOINTS
// ================================================================================

// Primary Breakpoints
$bp-col4:  320px;
$bp-col6:  480px;
$bp-col8:  640px;
$bp-col10: 800px;
$bp-col12: 960px;
$bp-col14: 1120px;
$bp-col16: 1280px;
$bp-col18: 1440px;
$bp-col20: 1600px;

// Secondary Breakpoints (1px < primary)
$bp-col4-max:  $bp-col8 - 1;
$bp-col8-max:  $bp-col12 - 1;
$bp-col12-max: $bp-col16 - 1;
