
// ===== POSITIONING HELPERS
// ================================================================================

// Center Horizontally
@mixin center-x() {
	position: absolute;
	left: 50%;
	transform: translateX(-50%);
}

// Center Horizontally with Flexbox
@mixin center-flex-x() {
	display: flex;
	justify-content: center;
}

// Center Vertically
@mixin center-y() {
	position: absolute;
	top: 50%;
	transform: translateY(-50%);
}

// Center Vertically with Flexbox
@mixin center-flex-y() {
	display: flex;
	align-items: center;
}

// Center Horizontally & Vertically
@mixin center-xy() {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

// Center Horizontally & Vertically with Flexbox
@mixin center-flex-xy() {
	display: flex;
	align-items: center;
	justify-content: center;
}