
// ===== FORM - GENERAL STYLING
// ================================================================================

form {
	margin: 0;
	padding: 0;
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;

	.form-disclaimer {
		display: flex;
		&__white {
			color: white;
		}
		input[type='checkbox'] {
			flex: 0 0 auto;
			appearance: none;
			width: 22px;
			height: 22px;
			background: white;
			outline: none;
			border: 2px solid #d0d6db;
			border-radius: 5px;
			margin-right: 12px;

			&:checked {
				background: $grey url('../images/icon-checkmark.png') center/14px 14px no-repeat;
			}
		}
		label {
			font-size: 10px !important;
			font-weight: $regular !important;
		}
	}
}

// Label
label {
	cursor: pointer;
}

// Normalize Number Input Fields
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

// Human Testing
input[name=breathing] {
	display: none !important;
}


// ===== FORM - LAYOUT HELPERS
// ================================================================================

// Wrapper for label/element combinations
.form-group {
	margin-bottom: 8px;
}


// ===== FORM ELEMENTS - COMMON STYLES / STATES
// ================================================================================

// Common text-field properties
%form-text-field {
	display: block;
	width: 100%;
	padding: 10px 15px;
	background: white;
	border: 2px solid #d0d6db;
	border-radius: 5px;
	color: inherit;
	font: $regular 16px/1 $font-main;
	outline: none;
	transition: box-shadow 0.2s ease;
	-webkit-appearance: none; // removes iOS inner-shadow
}

// Focus State
%form-focus {
	box-shadow: 1px 1px 3px 0 rgba(0,0,0,0.15);
}

// Disabled State
%form-disabled {
	cursor: default;
	opacity: 0.75;
	background: red;
}

// Read-Only State
%form-readonly {
	cursor: default;
}



// ===== FORM ELEMENTS - TEXT-BASED FIELDS
// ================================================================================

input[type=email],
input[type=number],
input[type=password],
input[type=search],
input[type=tel],
input[type=text],
input[type=url],
textarea {
	@extend %form-text-field;
	&:focus {
		// @extend %form-focus;
	}
	&:disabled {
		@extend %form-disabled;
	}
	&:read-only {
		@extend %form-readonly;
	}
	&::placeholder {
		color: inherit;
	}
}
textarea {
	resize: none;
}



// ===== FORM LAYOUT - FORMS WITH FLOATING LABELS
// ================================================================================

.form-floated-label {
	position: relative;
	width: 100%;
	label {
		position: absolute;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		padding-left: 16px; // match (visually) padding of text-fields
		color: $primary;
		cursor: text;
		font-family: $font-main;
		line-height: 45px; // match height of text-fields
		transition: color 0.2s ease, font-size 0.2s ease, line-height 0.2s ease, opacity 0.2s ease;
		z-index: 10;
		text-transform: uppercase;
		&:after {
			position: absolute;
			top: 1px;
			left: 10px;
			right: 10px;
			display: block;
			height: 100%;
			content: '';
			z-index: -1;
		}
	}
	&.col,
	&[class*="col-"] {
		label {
			padding-left: 21px;
		}
	}
	input[type=email],
	input[type=number],
	input[type=password],
	input[type=search],
	input[type=tel],
	input[type=text],
	input[type=url] {
		padding-top: 17px;
		padding-bottom: 2px;
	}
	textarea {
		padding-top: 22px; // account for unknown padding variation b/w input and textarea
	}
	.selector span {
		padding-top: 22px; // account for unknown height variation b/w text-fields and span
		padding-bottom: 8px; // account for unknown height variation b/w text-fields and span
	}
	&.has-focus,
	&.has-value {
		label {
			font-size: 10px !important;
			line-height: 24px;
			pointer-events: none;
			&:after {
				height: 20px;
			}
		}
	}

	&.half {
		width: 48%;
	}
}
