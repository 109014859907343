
// ===== GRADIENTS
// ================================================================================

// Horizontal Gradient (left to right)
@mixin gradient-x($color-alpha: #DDDDDD, $color-omega: #777777, $pos-alpha: 0%, $pos-omega: 100%) {
	background-image: linear-gradient(to right, $color-alpha $pos-alpha, $color-omega $pos-omega);
}

// Vertical Gradient (top to bottom)
@mixin gradient-y($color-alpha: #DDDDDD, $color-omega: #777777, $pos-alpha: 0%, $pos-omega: 100%) {
	background-image: linear-gradient(to bottom, $color-alpha $pos-alpha, $color-omega $pos-omega);
}

// Diagonal Gradient
@mixin gradient-diagonal($deg: 45deg, $color-alpha: #DDDDDD, $color-omega: #777777, $pos-alpha: 0%, $pos-omega: 100%) {
	background-image: linear-gradient($deg, $color-alpha $pos-alpha, $color-omega $pos-omega);
}

// Radial Gradient
@mixin gradient-radial($color-inner: #DDDDDD, $color-outer: #777777, $shape: ellipse) {
	background-image: radial-gradient($shape, $color-inner, $color-outer);
}