
// ===== MODAL
// ================================================================================

.modal {
	position: fixed;
	top: 0;
	left: 0;
	display: none;
	height: 100%;
	width: 100%;
	overflow: auto;
	z-index: 999;
}
.modal-body-wrapper {
	@include center-xy;
	width: 100%;
	max-width: 640px;
	padding: 0 30px;
	z-index: 2;
}
.modal-body {
	position: relative;
	padding: 30px;
	background: #FFFFFF;
	border-radius: 5px;
}
.modal-copy {
	max-height: calc(100vh - 60px - 60px); // (Viewport Height - Wrapper Padding - Body Padding)
	overflow: auto;
}
.modal-title {}
.modal-btn-close {
	position: absolute;
	top: 5px;
	right: 5px;
	height: 30px;
	width: 30px;
	background: white;
	border: solid #d7d7d7 1px;
	border-radius: 2px;
	cursor: pointer;
	overflow: hidden;
	text-indent: -999px;
	transition: background-color 0.25s ease;
	&:after {
		position: absolute;
		display: block;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		color: #999999;
		content: '\2715';
		font: $regular 16px/29px $font-main;
		text-align: center;
		text-indent: 0;
		transition: color 0.25s ease;
	}
	&:hover {
		background: lighten(#d7d7d7, 10%);
		&:after {
			color: #454545;
		}
	}
}
.modal-overlay {
	position: absolute;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	background: rgba(0,0,0,0.6);
	z-index: 1;
}