// ===== STEPPED FORM
// ================================================================================

form.stepped {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    color: $primary;
    background: $grey;
    padding: 30px 10px 20px 10px;
    z-index: 1;

    @include bp($bp-col8) {
        padding: 35px;
    }

    @include bp($bp-col12) {
        background: white;
        border-bottom: 6px solid $primary;
        box-shadow: 0 4px 15px rgba(black, 0.1);
    }

    @include bp($bp-col16) {
        padding: 30px 35px 15px 35px;
    }

    .disclaimer {
        font-size: 10px;
    }

    .steps-nav {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        max-width: 330px;
        margin-bottom: 25px;

        .step {
            cursor: pointer;
            position: relative;
            width: 33.333%;
            color: #d0d6db;
            font: 700 16px/1 $font-secondary;
            text-align: center;
            text-transform: uppercase;
            border-bottom: 6px solid #d0d6db;
            padding: 14px 0 8px 0;
            transition: color 0.2s;

            &:after {
                content: '';
                position: absolute;
                bottom: -6px;
                left: 0;
                width: 0;
                height: 6px;
                background: $secondary;
                transition: width 1s;
            }

            &:hover {
                color: darken($grey, 10%);
            }

            &.active,
            &.valid {
                color: $secondary;

                &:after {
                    width: 100%;
                }
            }
        }
    }

    .form-row {
        width: 100%;
        margin-bottom: 20px;

        &:last-of-type {
            margin-bottom: 0;
        }

        // 50/50 split rows
        &.split {
            display: flex;
            margin-bottom: 8px;

            > .form-row {
                margin-bottom: 0;
            }

            .form-row:not(:last-of-type) {
                margin-right: 12px;
            }
        }

        // Floated labels
        &.floated {
            position: relative;
            margin-bottom: 8px;

            label {
                position: absolute;
                top: 14px;
                left: 20px;
                font-weight: 400;
                text-transform: uppercase;
                margin: 0;
                transition: top 0.2s, font-size 0.2s;
            }

            // Active label state
            &.active {
                label {
                    font-size: 11px;
                    top: 4px;
                }
            }
        }

        // Error styles
        &.error {
            > label, .label, select {
                color: red;
            }
        }

        &.range {
            margin-bottom: 0;
        }

        &.state-field {
            label {
                pointer-events: none;
            }
        }

        &.button {
            text-align: center;
        }

        &.row-radio {
            margin-bottom: 25px;

            @include bp($bp-col12) {
                text-align: center;
            }
        }

    }

    // Label styles
    label,
    .label {
        display: table;
        font: 700 15px/1.15 $font;
        margin-bottom: 18px;

        @include bp($bp-col12) {
            font-size: 17px;
            margin: 0 auto 18px auto;
        }
    }

    // Select styles
    select {
        width: 100%;
        background: white url('../images/icon-arrow-bold.png') calc(100% - 9px) center/16px auto no-repeat;
        outline: none;
        border: 1px solid $grey;
        border-radius: 4px;
        color: inherit;
        padding: 20px 18px 4px !important;
    }

    // Container div for making extra columns
    .checkboxes, .radios {
        display: flex;
        justify-content: space-between;
        align-items: stretch;
        flex-wrap: wrap;

        label {
            display: flex;
            align-items: center;
            width: calc(50% - 4px);
            font-weight: $regular;
            font-size: 13px;
            background: #ebebeb;
            border: 2px solid #dadada;
            border-radius: 4px;
            padding: 8px;
            margin-bottom: 8px;

            @include bp($bp-col12) {
                color: $black;
                font-size: 15px;
                background: rgba(white, 0);
                border: none;
                margin-bottom: 0;
            }
        }
    }

    .radios {
        label {
            width: calc(33.333% - 3px);
        }

        @include bp($bp-col12) {
            max-width: 320px;
            margin: 0 auto;

            label {
                width: 33.333%;
            }
        }
    }

    // Checkboxes
    input[type='checkbox'] {
        flex: 0 0 auto;
        appearance: none;
        width: 30px;
        height: 30px;
        background: white;
        outline: none;
        border: 2px solid #dadada;
        border-radius: 4px;
        margin-right: 9px;

        &:checked {
            background: $grey url('../images/icon-checkmark.png') center/16px auto no-repeat;

            @include bp($bp-col12) {
                background: $grey url('../images/icon-checkmark.png') center/10px auto no-repeat;
            }
        }

        @include bp($bp-col12) {
            width: 22px;
            height: 22px;
            border-radius: 0;
            &:checked {
                background-size: 12px auto;
            }
        }
    }

    // Radio buttons
    input[type='radio'] {
        flex: 0 0 auto;
        appearance: none;
        width: 18px;
        height: 18px;
        background: white;
        outline: none;
        border: 2px solid #d0d6db;
        border-radius: 50%;
        margin-right: 9px;

        &:checked {
            background: $grey url('../images/icon-circle.png') center/10px auto no-repeat;
        }
    }

    // Range slider
    .range-slider {
        display: block;
        appearance: none;
        width: 80%;
        max-width: 350px;
        height: 10px;
        background: white;
        outline: none;
        border: 2px solid #d0d6db;
        border-radius: 4px;
        margin: 25px auto;

        &::-ms-track {
            width: 100%;
            height: 10px;
            background: white;
            border: 2px solid #d0d6db;
        }

        &::-webkit-slider-thumb {
            cursor: pointer;
            appearance: none;
            width: 40px;
            height: 40px;
            background: url('../images/icon-range-slider.svg') center/contain no-repeat;
            border: 2px solid #d0d6db;
            border-radius: 50%;
            z-index: 1;
            @include bp($bp-col12) {
                width: 30px;
                height: 30px;
            }
        }

        &::-moz-range-thumb {
            cursor: pointer;
            appearance: none;
            width: 40px;
            height: 40px;
            background: url('../images/icon-range-slider.svg') center/contain no-repeat;
            border: 2px solid #d0d6db;
            border-radius: 50%;
            z-index: 1;
            @include bp($bp-col12) {
                width: 30px;
                height: 30px;
            }
        }

        &::-ms-thumb {
            cursor: pointer;
            appearance: none;
            width: 40px;
            height: 40px;
            background: url('../images/icon-range-slider.svg') center/contain no-repeat;
            border: 2px solid #d0d6db;
            border-radius: 50%;
            z-index: 1;
            @include bp($bp-col12) {
                width: 30px;
                height: 30px;
            }
        }
    }

    .range-display {
        width: 80%;
        color: $primary;
        font: 400 14px/1 $font-secondary;
        text-align: center;
        margin: 0 auto;

        @include bp($bp-col8) {
            width: 100%;
        }

        .value {
            font-size: 20px;
            font-weight: 700;
        }
    }

    // General input and textarea style
    .input {
        appearance: none;
        width: 100%;
        color: $black;
        background: white;
        outline: none;
        border: 2px solid #d0d6db;
        border-radius: 4px;
        padding: 19px 18px 1px 18px;
    }

    .btn {
        width: auto;
        margin: 10px auto 0 auto;
    }

    .steps-slider {
        max-width: 400px;

        @include bp($bp-col8) {
            max-width: none;
        }
    }
}
