// ===== HEADER
// ================================================================================

.header-main {
	position: sticky;
	top: 0;
	right: 0;
	left: 0;
	z-index: 100;
	width: 100%;
	background: white;
	box-shadow: 0 4px 15px rgba(black, 0.1);

	.eyebrow {
		color: $blue;
		font: $medium 14px/1.25 $font;
		text-align: center;
		background: $yellow;
		padding: 8px 30px;

		@include bp($bp-col12) {
			font-size: 17px;
			padding: 12px 30px;
		}
	}

	> .perimeter {
		display: flex;
		align-items: center;
		flex-wrap: wrap;
		padding: 14px 12px;

		&>* {
			flex: 1 1 50%;
		}

		@include bp($bp-col12) {
			align-items: center;
			justify-content: space-between;
			height: 145px;
			padding: 0 20px;

			&>* {
				flex: 0 1 auto;
			}
		}

		.header-logo {
			display: flex;
			flex-direction: column;
			justify-content: center;
			color: $blue;
			font: $regular 12px/1 $font;
			text-decoration: none;

			@include bp($bp-col12) {
				font-size: 15px;
			}

			.byline {
				text-align: center;
			}

			svg,
			img {
				width: 100%;
				max-width: 145px;

				@include bp(400px) {
					max-width: 175px;
				}

				@include bp($bp-col12) {
					max-width: 320px;
				}
			}
		}

		.header-features {
			display: none;
			justify-content: center;
			color: $blue;
			font: $regular 16px/1 $font;

			@include bp($bp-col12) {
				display: flex;
				margin: 0 auto;
				order: 2;
			}

			@include bp($bp-col14) {
				font: $regular 20px/1 $font;
			}

			.feature {
				display: flex;
				align-items: center;

				@include bp($bp-col12) {
					justify-content: flex-start;
				}

				&:not(:last-of-type) {
					margin-bottom: 7px;
				}

				svg {
					width: 20px;
					height: 20px;
					margin-right: 11px;
				}
			}

			strong {
				font-weight: $bold;
				margin-right: 6px;
			}
		}

		.header-cta {
			display: flex;
			flex-direction: column;
			align-items: stretch;

			@include bp($bp-col12) {
				order: 3;
			}

			.title {
				display: block;
				color: $blue;
				font: $bold 12px/1 $font;
				text-transform: uppercase;
				margin: 0 0 5px auto;

				@include bp($bp-col12) {
					font-size: 17px;
				}
			}

			.phone {
				display: inline-flex;
				flex-direction: column;
				justify-content: center;
				align-items: center;
				width: auto;
				color: white;
				font: $bold 18px/1 $font-open;
				margin: 0 0 0 auto;
				text-align: center;
				text-transform: uppercase;
				background: $orange;
				padding: 8px 10px 10px 10px;

				@include bp($bp-col12) {
					width: 100%;
					font-size: 25px;
				}

				.text {
					display: none;
					font: $regular 15px/1 $font-open;
					letter-spacing: 1.75px;
					margin-bottom: 4px;
				}

				.display {
					display: flex;
					justify-content: center;
					align-items: center;

					.icon {
						display: flex;
						margin: 0 6px -2px 0;

						@include bp($bp-col12) {
							display: none;
						}

						svg {
							width: 14px;
							height: 14px;
							fill: white;
						}
					}
				}
			}
		}
	}
}

.pre-body {
	display: flex;
	justify-content: center;
	background-color: #fff;
	color: $blue;
	font: $regular 16px/1 $font;
	padding: 15px 0;

	@include bp($bp-col12) {
		display: none;
	}

	li {
		display: flex;
		align-items: center;

		&:not(:last-of-type) {
			margin-bottom: 7px;
		}

		svg {
			width: 20px;
			height: 20px;
			margin-right: 11px;
		}
	}

	strong {
		font-weight: $bold;
		margin-right: 6px;
	}
}
