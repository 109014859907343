// ===== GENERAL STYLES
// ================================================================================

* {
	-webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
	&,
	&:before,
	&:after {
		box-sizing: border-box;
	}
}

html {
	font-size: 16px;
}

body {
	margin: 0;
	padding: 0;
	background: white;
	color: $primary;
	font: $regular 16px/1.4 $font-main;
	@include bp($bp-col12) {
		font-size: 18px;
	}
}

.section {
	padding: 50px 0;

	@include bp($bp-col8) {
		padding: 75px 0;
	}
}


// ===== TYPOGRAPHY - HEADINGS
// ================================================================================

h1, h2, h3, h4, h5, h6 {
	margin: 0;
	padding: 0;
	color: inherit;
	font-family: inherit;
	font-size: inherit;
	font-weight: inherit;
	line-height: inherit;
	text-transform: inherit;
}

.section-title {
	position: relative;
	color: $primary;
	font: $bold 22px/1.3 $font-main;
	text-align: center;
	padding-bottom: 18px;
	margin-bottom: 25px;

	&:after {
		content: '';
		position: absolute;
		bottom: 0;
		left: 50%;
		transform: translateX(-50%);
		width: 100%;
		max-width: 150px;
		height: 3px;
		background: $secondary;
	}

	@include bp($bp-col12) {
		font-size: 34px;
		margin-bottom: 40px;

		&:after {
			max-width: 300px;
		}
	}
}

.section-subtitle {
	color: $black;
	font: $medium 15px/1.2 $font;
	letter-spacing: 1px;
	text-transform: uppercase;
	margin: 0 auto 30px auto;

	@include bp($bp-col12) {
		font-size: 26px;
		margin-bottom: 55px;
	}
}


// ===== TYPOGRAPHY - PARAGRAPHS
// ================================================================================

p {
	margin: 20px 0;
}


// ===== TYPOGRAPHY - ANCHORS / LINKS
// ================================================================================

a {
	color: inherit;
	cursor: pointer;
	text-decoration: underline;
	&:hover {
		text-decoration: none;
	}
	&[href*="tel:"] {
		color: inherit;
		text-decoration: none !important;
	}
}


// ===== TYPOGRAPHY - FONT WEIGHTS / STYLES
// ================================================================================

b, strong {
	font-weight: $bold;
}
i, em {
	font-style: italic;
}


// ===== TYPOGRAPHY - PREFORMATTED TEXT
// ================================================================================

pre {
	font-size: inherit;
	white-space: pre-wrap;
}


// ===== IMAGES
// ================================================================================

img {
	height: auto;
	max-width: 100%;
}
