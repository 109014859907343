
// ===== FOOTER - TOP
// ================================================================================

.footer-top {
	color: $primary;
	font: $regular 20px/1 $font;
	text-align: center;
	padding: 25px 0;

	@include bp($bp-col8) {
		.perimeter {
			display: flex;
			justify-content: center;
			flex-wrap: wrap;
		}
	}

	@include bp($bp-col12) {
		padding: 40px 0 50px 0;

		.perimeter {
			max-width: 800px;
			flex-wrap: nowrap;
			justify-content: space-between;
			align-items: center;
		}
	}
}

.footer-links {
	width: 100%;
	text-align: center;

	@include bp($bp-col12) {
		width: auto;
		margin-right: 50px;
	}

	@include bp($bp-col16) {
		margin-right: 130px;
	}

	.footer-logo {
		margin: 0 auto 30px;

		@include bp($bp-col12) {
			margin: 0;
		}

		img {
			display: block;
			width: 240px;
			height: auto;
			fill: white;
			margin: 0 auto;

			@include bp($bp-col12) {
				width: 330px;
				margin: 0;
			}
		}

		.byline {
			font: $medium 13px/1.2 $font;
			margin-top: 5px;

			@include bp($bp-col12) {
				font-weight: $regular;	
				font-size: 16px;
			}
		}

	}

	.footer-nav {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		margin-bottom: 30px;

		li {
			margin-bottom: 20px;
		}

		@include bp($bp-col8) {
			flex-direction: row;
			margin-bottom: 50px;

			li {
				margin: 0;
			}

			li:not(:last-of-type) {
				margin-right: 30px;
			}
		}

		@include bp($bp-col12) {
			flex-direction: column;
			margin: 0;

			li:not(:last-of-type) {
				margin: 0 0 20px 0;
			}
		}

		@include bp($bp-col16) {
			flex-direction: row;

			li:not(:last-of-type) {
				margin: 0 30px 0 0;
			}
		}

		a {
			color: inherit;
			font-size: 17px;
			text-decoration: none;
			text-transform: uppercase;
			white-space: nowrap;
			&:hover {
				text-decoration: underline;
			}
		}

	}
}

.footer-locations {

	.title {
		font: $medium 15px/1.2 $font;
		letter-spacing: 1px;
		text-transform: uppercase;
		margin-bottom: 5px;

		@include bp($bp-col12) {
			font-size: 18px;
		}
	}

	.phone {
		color: $orange;
		display: inline-block;
		font: $bold 25px/1 $font;
		margin: 0 auto 10px auto;

		@include bp($bp-col8) {
			font-size: 28px;
		}

		@include bp($bp-col12) {
			font-size: 30px;
			margin-bottom: 0;
		}
	}

	.address {
		font: $regular 16px/1.65 $font;
		margin-bottom: 30px;

		@include bp($bp-col12) {
			font-size: 18px;
			margin: 0;
		}
	}
}

.footer-hours {

	.day {
		color: $black;
		font: $regular 16px/1.65 $font;

		@include bp($bp-col12) {
			font-size: 18px;
		}
	}
}


// ===== FOOTER - BOTTOM
// ================================================================================

.footer-bottom {
	color: white;
	font: $regular 14px/1.2 $font;
	text-transform: uppercase;
	background: $black;
	padding: 22px 0 95px 0;

	a {
		color: inherit;
		text-decoration: none;
		&:hover {
			text-decoration: underline;
		}
	}

	@include bp($bp-col8) {
		text-align: center;
	}

	@include bp($bp-col12) {
		text-align: left;
		padding-bottom: 65px;
		
		.perimeter {
			display: flex;
			align-items: center;
		}
	}
	
	@include bp($bp-col16) {
		font-size: 15px;
	}

}

.footer-copyright {
	text-align: center;
	margin-bottom: 20px;

	@include bp(max-width $bp-col4-max) {
		line-height: 1.5;
		span {
			display: block;
		}
	}

	@include bp($bp-col8) {
		flex: 1;

		span:not(:last-child):after {
			content: ' | ';
		}
	}

	@include bp($bp-col12) {
		text-align: left;
		margin-bottom: 0;
	}
}

.footer-credits {
	display: flex;
	flex-direction: column;
	align-items: center;
	text-align: center;

	@include bp(max-width $bp-col4-max) {
		svg {
			margin: 10px auto 0 auto;
		}
	}

	@include bp($bp-col8) {
		flex-direction: row;
		justify-content: center;

		a {
			display: flex;
			align-items: center;
		}
		svg {
			margin-left: 8px;
		}
	}

	svg {
		display: block;
		width: 105px;
		height: auto;
		fill: white;
	}
}
