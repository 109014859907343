
// ===== BUTTON
// ================================================================================

// Button Normalization
%btn-reset {
	display: inline-flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	outline: none;
	overflow: hidden;
	text-align: center;
	text-decoration: none !important;
}

// Global Button Style
.btn {
	@extend %btn-reset;
	color: white;
	font: $bold 19px/1 $font-main;
	text-transform: uppercase;
	background: $secondary;
	border: 0;
	padding: 14px 26px;
	transition: all 0.2s;

	@include bp($bp-col8) {
		font-size: 20px;
		padding: 14px 40px;
	}

	@include bp($bp-col12) {
		padding: 14px 24px;
	}

	// Disabled state
	&.disabled {
		pointer-events: none !important;
		color: rgba(black, 0) !important;
		background: $secondary url('../img/loading.gif') center/contain no-repeat !important;
		transition: color 0.2s;
	}
}



// ===== BUTTON - COLOR / STYLE VARIATIONS
// ================================================================================

.btn-teal {
	cursor: pointer;
	background-color: $secondary;
	transition: background-color 0.2s;
	&:hover {
		background-color: lighten($secondary, 10%);
	}
}
.btn-gradient {
	@include gradient-x($secondary, $primary);
}



// ===== BUTTON - SIZE VARIATIONS
// ================================================================================

.btn-sm {
	padding: 6px 15px;
	font-size: 13px;
}
.btn-lg {
	padding: 15px 50px;
	font-size: 18px;
}
