// REUSABLE ANIMATIONS
// ====================================================================

.animate {
    opacity: 0;

    &.lazyloaded {

        &.from-top {
            animation: fromTop 0.5s forwards;
        }
        &.from-bottom {
            animation: fromBottom 0.5s forwards;
        }
        &.from-left {
            animation: fromLeft 0.5s forwards;
        }
        &.from-right {
            animation: fromRight 0.5s forwards;
        }

        // Add a staggered delay
        &.delay {
            animation-delay: 0.25s;
        }

    }
}

@keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
}

@keyframes fromTop {
    0% {
        opacity: 0;
        transform: translateY(-30px);
    }
    100% {
        opacity: 1;
        transform: translateY(0px);
    }
}

@keyframes fromBottom {
    0% {
        opacity: 0;
        transform: translateY(30px);
    }
    100% {
        opacity: 1;
        transform: translateY(0px);
    }
}

@keyframes fromLeft {
    0% {
        opacity: 0;
        transform: translateX(-30px);
    }
    100% {
        opacity: 1;
        transform: translateX(0px);
    }
}

@keyframes fromRight {
    0% {
        opacity: 0;
        transform: translateX(30px);
    }
    100% {
        opacity: 1;
        transform: translateX(0px);
    }
}